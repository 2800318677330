<template>
  <ValidationProvider v-slot="{ failed, errors }" :rules="rules" :name="name" :vid="vid">
    <div class="form-group text-editor-action-wrapper" :style="{ 'margin-bottom': marginBottom }">
      <label v-if="label" class="form-control-label">
        {{ label }}
        <feather v-if="explain" style="vertical-align:bottom;margin-left:10px;cursor:pointer;" type="help-circle"
          @click="handleExplain" />
        <span v-if="sublabel" class="text-danger">{{ sublabel }}</span>
      </label>
      <div class="editor-wrapper d-block position-relative">
        <loading :active.sync="isProcessing" :can-cancel="false" :is-full-page="false">
        </loading>
        <!-- <froala v-model="iValue" :config="editorConfig"
          :tag="'textarea'" /> -->
        <!-- <textarea ref="editor"></textarea> -->
        <div ref="editor"><div style="font-size: 18px; color: #3c4043;"></div></div>
        <b-form-invalid-feedback v-if="errors.length" :style="{ marginLeft: icon ? '48px' : '' }">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </div>
      <div class="row mt-2">
        <div class="col-12 d-flex align-items-center justify-content-start">
          <template v-if="!hideUpload">
            <button v-b-tooltip.hover title="Add Attachments" type="button" class="btn btn-outline-dark btn-sm" @click="showModal">
              <i class="uil uil-upload"></i>
              <span v-if="imageLength" class="badge position-absolute rounded-pill top-0 start-100 bg-primary text-white">
                {{ imageLength }}
              </span>
            </button>
          </template>
          <div v-if="mergefield" class="d-block ml-2">
            <MergeFieldsGroup 
              :show-keyword="true"
              :show-contact-link="true"
              :hide-title="true"
              :margin-bottom-emtpy="true"
              dropdown-size="sm"
              position="up"
              @selected="appendField"
            />
          </div>
        </div>
      </div>
      <span v-if="help" class="help-block">
        <small>{{ help }}</small>
      </span>

    </div>

    <b-modal id="image-upload-modal" v-model="isShowModal" title="Image" size="lg"
      @shown="manuallyAddFile(object.images)">
      <p class="text-primary font-weight-bold">Maximum image size is 5MB</p>
      <vue-dropzone :id="vid" ref="dropzoneUpload" :options="dropzoneOptions" :use-custom-slot="true"
        @vdropzone-sending="sendingFile" @vdropzone-removed-file="onFileRemoved" @vdropzone-complete="onComplete"
        @vdropzone-error="onUploadFailed">
        <div class="dropzone-custom-content">
          <i class="h1 text-muted uil-cloud-upload"></i>
          <h3 class="dropzone-custom-title">
            Drag & Drop or Click To Choose A File To Upload.
          </h3>
        </div>
      </vue-dropzone>

      <template v-slot:modal-footer>
        <b-button v-if="imageLength" variant="danger" @click="removeAllFiles">
          Remove Files
        </b-button>
        <b-button v-if="imageLength" variant="primary" @click="save">
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationProvider>
</template>
  
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'

// froala-editor
import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone, Loading,
  },

  props: {
    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    explain: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: String,
      default: '1rem',
    },
    smsLength: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: null,
    },
    hideUpload: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: null
    },
    toolbarType: {
      type: String,
      default: 'mini',
    },
    mergefield: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Your Email...',
    },
  },

  data() {
    return {
      isShowModal: false,
      isProcessing: false,
      dropzoneOptions: {
        maxFilesize: 5,
        maxFiles: 10,
        // acceptedFiles:
        //   "image/jpeg, image/gif, image/png, audio/basic, audio/L24, audio/mp4, audio/mpeg ,audio/ogg, audio/vorbis, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr, video/mpeg, video/mp4, video/quicktime, video/webm, video/3gpp, video/3gpp2, video/3gpp-tt, video/H261, video/H263, video/H263-1998, video/H263-2000, video/H264, image/jpeg, image/gif, image/png, image/bmp, text/vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, application/pdf",
        url: `${process.env.VUE_APP_BASE_API}api/v1/uploads/image`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
      editor: null,
      editorInitialized: false,
      editorConfig: {
        key: process.env.VUE_APP_FROALA_API_KEY,
        heightMin: 100,
        // heightMax: 450,
        quickInsertEnabled: false,
        toolbarVisibleWithoutSelection: false,
        useClasses: false,
        enter: FroalaEditor.ENTER_BR,
        fontFamilySelection: true,
        fontSizeDefaultSelection: '18',
        fontSizeSelection: true,
        linkAlwaysBlank: true,
        linkStyles: {
          'fr-green': 'Green',
          'fr-strong': 'Thick',
          'underlineClass': 'Underline',
        },
        toolbarButtons: {
          'moreText': {
            'buttons': [
              'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'alignLeft', 
              'alignCenter', 'alignRight', 'alignJustify', 'insertLink', 'insertImage', 'emoticons', 'formatUL', 'formatOL', 
              'lineHeight', 'strikeThrough', 'paragraphFormat', 'paragraphStyle', 'insertHR', 'outdent', 'indent', 'quote', 'undo', 'redo', 'fullscreen', 'html'
            ],
            'buttonsVisible': 10,
          },
        },
        emoticonsUseImage: false,
        placeholderText: this.placeholder,
        requestHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        imageUpload: true,
        imageUploadMethod: 'POST',
        imageUploadParam: 'file',
        imageUploadParams: {
          link: true,
        },
        imageUploadRemoteUrls: true,
        imageUploadURL: process.env.VUE_APP_BASE_API + 'api/v1/uploads/image',
        ...this.options,
        events: {
          initialized: () => {
            this.initValue()
            this.initEvents()
          },
          destroy: () => { }
        },
      },
    }
  },

  computed: {
    imageLength() {
      if (this.object && this.object.images)
        return this.object.images.length
      return 0
    },

    user() {
      return this.$store.getters['auth/user']
    },

    business() {
      return this.user && this.user.business
    },
  },

  watch: {
    // value: function (newVal, oldVal) {
    //   if (this.editor && this.value !== this.editor.html.get()) {
    //     this.initValue()
    //   }
    // },
  },

  mounted() {
    if (this.toolbarType === 'full') {
      this.editorConfig.toolbarButtons = {
        'moreMisc': {
          'buttons': [
            'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'alignLeft', 
            'alignCenter', 'alignRight', 'alignJustify', 'insertLink', 'insertImage', 'emoticons', 'formatUL', 'formatOL', 
            'lineHeight', 'strikeThrough', 'paragraphFormat', 'paragraphStyle', 'insertHR', 'outdent', 'indent', 'quote', 'undo', 'redo', 'fullscreen', 'html'
          ],
          'buttonsVisible': 16,
          'align': 'left',
        }
      }
    }
    this.initEditor()
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy()
      this.editor = null
    }
  },

  methods: {
    initValue() {
      this.editor.html.set(`<div style="font-size: 18px; color: #3c4043;">${this.value ? this.value: ''}</div>`)
    },
    reset() {
      this.editor.html.set(`<div style="font-size: 18px; color: #3c4043;"></div>`)
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append("file", file)
      this.isProcessing = true
      this.$store
        .dispatch('upload/image', formData)
        .then((res) => {
          const url = res.data.path
          Editor.insertEmbed(cursorLocation, "image", url)
          resetUploader()
          this.isProcessing = false
        })
        .catch((err) => {
          console.log(err)
          this.isProcessing = false
        })
    },

    initEditor() {
      // let _this = this
      // FroalaEditor.DefineIcon('Contact Info', { NAME: 'Contact Info', template: 'text' });
      // FroalaEditor.RegisterCommand('Contact Info', {
      //   title: 'Contact Info',
      //   type: 'dropdown',
      //   focus: false,
      //   undo: false,
      //   refreshAfterCallback: true,
      //   options: {
      //     '[first_name]': 'First Name',
      //     '[last_name]': 'Last Name',
      //     '[email]': 'Email Address',
      //     '[phone_number]': 'Phone Number',
      //     '[birthday]': 'Birthday',
      //     '[anniversary]': 'Anniversary',
      //     '[business_name]': 'Business Name',
      //     '[website_url]': 'Website URL',
      //     '[dynamic_code]': 'Dynamic Code',
      //     '[referral_link]': 'Referral Link',
      //     '[points_page]': 'Points Page',
      //     '[gotowebinar_join_url]': 'GotoWebinar Join URL',
      //   },
      //   callback: function (cmd, val) {
      //     _this.editor.html.insert(val)
      //   },
      // });

      if (!this.editorInitialized) {
        this.editor = new FroalaEditor(this.$refs.editor, this.editorConfig)
        this.editorInitialized = true
      }
    },

    initEvents() {
      let _this = this
      _this.editor.events.on('contentChanged', function (e) {
        let currentContent = _this.editor.html.get()
        if (!currentContent.startsWith('<div style="font-size: 18px; color: #3c4043;">')) {
          currentContent = `<div style="font-size: 18px; color: #3c4043;">` + currentContent + `</div>`
        }
        if (_this.value !== currentContent) {
          _this.$emit('input', currentContent)
          _this.$emit('changed', currentContent)
        }
      }, false);

    },

    appendField(field) {
      this.editor.events.focus(true);
      this.editor.html.insert(field)
    },

    sendingFile() { },

    manuallyAddFile(files) {
      files.forEach((file) => {
        var url = file.path;
        this.$refs.dropzoneUpload.manuallyAddFile(file, url);
      });
    },

    removeAllFiles() {
      this.$refs.dropzoneUpload.removeAllFiles()
      this.object.images = []
    },

    onFileRemoved(file) {
    },

    onComplete(res) {
      if (res.status === "success") {
        this.object.images.push(JSON.parse(res.xhr.response)["data"])
      }
    },

    onUploadFailed(file, message) {
      if (message) {
        let content = message
        if (message.errors && message.errors.file) {
          content = message.errors.file[0]
        }
        this.$toast.open({
          message: content,
          type: 'error',
        })
      }
      this.$refs.dropzoneUpload.removeFile(file)
    },

    // handleChanged() {
    //   this.$emit('changed', this.iValue)
    // },

    handleExplain() {
      this.$emit('clickExplain', true)
    },

    showModal() {
      this.isShowModal = true
    },

    save() {
      this.isShowModal = false
    },
  },
}
</script>

<style lang="scss">
.fr-box.fr-basic .fr-element {
  font-size: 18px; color: #3c4043;
}

.fr-toolbar.fr-top {
  border-radius: 0 !important;
}

.fr-toolbar {
  border: none !important;

  .fr-btn-grp {
    margin: 0 6px;
  }

  .fr-command.fr-btn {
    svg {
      // margin: 5px 5px;
    }
  }
}

.text-editor-action-wrapper {
  .editor-wrapper {
    box-shadow: 0px 0px 2px darkgrey;
    border-radius: 3px;
  }

  .fr-toolbar.fr-sticky-on {
    z-index: 999;
  }

  .fr-second-toolbar {
    display: none;
  }
}
.underlineClass {
  text-decoration: underline!important;
}
</style>